/** @jsx jsx */

import { Link } from 'gatsby'
import { FC, ReactElement } from 'react'
import { Flex, jsx } from 'theme-ui'

interface ListWithImagesProps {
  containerStyles?: {}
  sectionTitle?: string
  sectionDescription?: string
  products: {
    id: string
    to?: string
    image: string | ReactElement
    title: string
    description: string
    secondaryDescription?: string
    learnMore?: string
  }[]
}

const ListWithImages: FC<ListWithImagesProps> = ({ sectionTitle, sectionDescription, products, containerStyles }) => {
  return (
    <section sx={containerStyles}>
      {(sectionTitle || sectionDescription) && (
        <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4 }}>
          {sectionTitle && <h2 sx={{ mt: 0, mb: 3, fontSize: `2rem` }}>{sectionTitle}</h2>}
          {sectionDescription && <p sx={{ maxWidth: `55ch`, mt: 0, mb: 5, fontSize: 3 }}>{sectionDescription}</p>}
        </div>
      )}

      {products.map(({ id, to, image, title, description, secondaryDescription, learnMore }, index) => {
        return (
          <div key={id} {...{ id }} sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4 }}>
            <Flex
              as={to ? Link : `div`}
              {...{ to }}
              sx={{
                pt: index ? 5 : 0,
                flexDirection: [`column`, null, index % 2 === 0 ? `row` : `row-reverse`],
                justifyContent: `space-between`,
                textDecoration: `none`,
                color: `inherit`,
              }}
            >
              <Flex
                sx={{
                  maxWidth: `100%`,
                  width: 377,
                  minHeight: 312,
                  mb: [4, null, 0],
                  flex: `377px 0 0`,
                  alignItems: `center`,
                  justifyContent: `center`,
                  fontWeight: 800,
                  fontSize: [`6rem`, null, `10rem`],
                  color: `white_slate7`,
                }}
              >
                {image}
              </Flex>
              <div
                sx={{
                  maxWidth: `55ch`,
                  pr: [0, null, index % 2 === 0 ? 0 : 4],
                  pl: [0, null, index % 2 === 0 ? 4 : 0],
                  flex: 1,
                  alignSelf: [`flex-start`, null, `center`],
                }}
              >
                <h2 sx={{ mt: 0, mb: 1, fontSize: 5 }}>{title}</h2>
                <p sx={{ fontSize: 3 }}>{description}</p>
                <p sx={{ mb: 0 }}>{secondaryDescription}</p>
                {learnMore && (
                  <p sx={{ color: `primary`, [`a:hover &, a:focus &`]: { textDecoration: `underline` } }}>
                    {learnMore}
                  </p>
                )}
              </div>
            </Flex>
          </div>
        )
      })}
    </section>
  )
}

export default ListWithImages
