/** @jsx jsx */

import { jsx } from 'theme-ui'

import cartaCross from '../assets/features/carta-cross.png'
import tenderOffersAndCompanyRepurchases from '../assets/features/tender-offers-and-company-repurchases.png'
import chevronIconPath from '../assets/icons/chevron.svg'
import donutIconPath from '../assets/icons/donut.svg'
import listIconPath from '../assets/icons/list.svg'
import onboardingIconPath from '../assets/icons/onboarding.svg'
import routes from '../constants/routes'

export const halo = {
  title: `Private companies: Equity liquidity solutions`,
  route: routes.privateCompanies,
  description: `CartaX offers low-touch liquidity solutions for private companies that allow for a diverse set of transactions through a single, simple platform.`,
}

export const intro = {
  title: (
    <span>
      Unlock the value of <br sx={{ display: [`none`, `block`] }} />
      ownership with CartaX
    </span>
  ),
  description: `Low-touch liquidity solutions for private companies`,
  secondaryDescription: `Choose from a diverse set of transaction configurations offered through a single, simple platform.`,
}

export const products = [
  {
    id: `carta-cross`,
    to: routes.privateCompaniesCartaCross,
    image: <img src={cartaCross} alt="" sx={{ maxWidth: `100%`, maxHeight: `336px` }} />,
    title: `Carta Cross`,
    description: `The premier CartaX transaction framework for recurring secondary auctions.`,
    secondaryDescription: `Bring transparency and efficiency into the market for your stock, and ensure optimal liquidity.`,
    learnMore: `Learn more about Carta Cross »`,
  },
  {
    id: `tender-offers-and-company-repurchases`,
    image: <img src={tenderOffersAndCompanyRepurchases} alt="" sx={{ maxWidth: `100%`, maxHeight: `336px` }} />,
    title: `Tender offers and company repurchases`,
    description: `Offer episodic liquidity to shareholders via third party purchases or buybacks.`,
    secondaryDescription: `Decrease setup and post transaction administration through Carta cap table integration.`,
  },
]

export const harnessThePower = {
  title: `Harness the power of Carta’s network`,
  description: `All of our private market liquidity solutions offer:`,
  items: [
    {
      icon: donutIconPath,
      title: `Seamless cap table integration`,
      description: `Reduce configuration overhead and automatically update your cap table post-transaction.`,
    },
    {
      icon: onboardingIconPath,
      title: `Efficient onboarding process`,
      description: `Benefit from seamless onboarding as an existing Carta client, or an expedited process as a new Carta client, making the cap table transfer painless.`,
    },
    {
      icon: chevronIconPath,
      title: `Diverse network of institutional investors`,
      description: `Cultivate investor relationships as your business matures and your investor needs change.`,
    },
    {
      icon: listIconPath,
      title: `Simplified liquidity experience`,
      description: `Participants review transaction details, sign documents, and enter orders through a single log-in.`,
    },
  ],
}
