/** @jsx jsx */

import { FC } from 'react'
import { Flex, jsx } from 'theme-ui'

import routes, { RouteName } from '../constants/routes'
import Button from './button'
import UniversalLink from './universal-link'

interface SignUpCallToActionProps {
  id?: string
  to?: Pick<
    RouteName,
    `institutionalInvestorsRequestInformation` | `privateCompaniesRequestInformation` | `requestInformation`
  >
}

const SignUpCallToAction: FC<SignUpCallToActionProps> = ({ to = routes.requestInformation }) => {
  return (
    <section
      id="learn-more"
      sx={{ py: 4, color: `white`, backgroundImage: `var(--gradient)`, [`@media print`]: { display: `none` } }}
    >
      <Flex sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, p: 4, justifyContent: `center` }}>
        <Flex
          sx={{
            width: `60ch`,
            borderRadius: 6,
            p: 4,
            flexDirection: `column`,
            bg: `rgba(255, 255, 255, 0.0625)`,
            boxShadow: `0 2px 8px -2px rgba(0, 0, 0, 0.5)`,
          }}
        >
          <h2 sx={{ mt: 0, textAlign: `center` }}>Learn more about CartaX</h2>
          <Button as={UniversalLink} {...{ to }} sx={{ borderColor: `black`, color: `white`, bg: `black` }}>
            Request information
          </Button>
        </Flex>
      </Flex>
    </section>
  )
}

export default SignUpCallToAction
