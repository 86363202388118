/** @jsx jsx */

import { FC, ReactElement } from 'react'
import { Flex, jsx } from 'theme-ui'

import routes from '../constants/routes'

interface BulletPointsProps {
  containerStyles?: {}
  title?: string
  description?: string
  secondaryDescription?: string
  items: {
    icon?: string
    title: string
    description: string | ReactElement
  }[]
}

const BulletPoints: FC<BulletPointsProps> = ({
  location,
  containerStyles,
  title,
  description,
  secondaryDescription,
  items,
}) => {
  const isHome = location?.pathname === routes.home

  return (
    <section sx={containerStyles}>
      <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4 }}>
        <div sx={{ maxWidth: `65ch` }}>
          <h2 sx={{ mt: 0, fontSize: `2rem` }}>{title}</h2>
          {description && <p sx={{ fontSize: 3 }}>{description}</p>}
          {secondaryDescription && <p sx={{ mb: 0 }}>{secondaryDescription}</p>}
        </div>

        <Flex
          as="ul"
          sx={{
            my: 0,
            mx: -4,
            pl: 0,
            display: [`block`, `block`, `flex`],
            listStyleType: `none`,
            flexWrap: `wrap`,
            maxWidth: [`55ch`, null, `none`],
            bg: isHome ? `underlay` : `transparent`,
          }}
        >
          {items.map(({ icon, title, description }) => {
            return (
              <li key={title} sx={{ width: [`100%`, null, `50%`], p: 4 }}>
                {icon && <img src={icon} alt="" sx={{ mb: 3, size: [48], display: `block` }} />}
                <h3 sx={{ mt: 0, mb: 3 }}>{title}</h3>
                <p sx={{ my: 0 }}>{description}</p>
              </li>
            )
          })}
        </Flex>
      </div>
    </section>
  )
}

export default BulletPoints
