/** @jsx jsx */

import { FC, ReactElement } from 'react'
import { Box, jsx } from 'theme-ui'

import HorizontalDivider from './horizontal-divider'

export interface IntroProps {
  lead?: string | ReactElement
  title: string | ReactElement
  url?: string
  description?: string
  secondaryDescription?: string | ReactElement
  image?: {
    sx: {}
    src: string
    alt: string
  }
}

const Intro: FC<IntroProps> = ({ lead, image, title, description, secondaryDescription }) => {
  return (
    <section sx={{ pt: [2, 0] }}>
      <Box
        sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, pt: [5, 6], pb: 6, px: 4, [`@media print`]: { pb: 4 } }}
      >
        <Box>
          {lead && (
            <p sx={{ fontWeight: 500, fontSize: 1, textTransform: `uppercase`, letterSpacing: `0.1em` }}>{lead}</p>
          )}
          {image && <img {...image} />}
          <h1 sx={{ maxWidth: `55ch`, my: 0, fontSize: 6 }}>{title}</h1>
          {description && (
            <div
              dangerouslySetInnerHTML={{ __html: description }}
              sx={{ maxWidth: `55ch`, mt: 3, mb: 0, fontSize: 4, [`& > p`]: { my: 0 } }}
            />
          )}
          {secondaryDescription && <p sx={{ maxWidth: `70ch`, mt: 3, mb: 0 }}>{secondaryDescription}</p>}
        </Box>
      </Box>
      <HorizontalDivider />
    </section>
  )
}

export default Intro
