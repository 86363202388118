/** @jsx jsx */

import { FC } from 'react'
import { Box, jsx } from 'theme-ui'

import theme from '../gatsby-plugin-theme-ui'

const Button: FC = ({ ...props }) => {
  return (
    <Box
      as="button"
      sx={{
        display: `inline-flex`,
        alignItems: `center`,
        whiteSpace: `nowrap`,
        cursor: `pointer`,
        appearance: `none`,
        height: 40,
        fontWeight: `500`,
        fontSize: `inherit`,
        fontFamily: `inherit`,
        color: `background`,
        bg: `primary`,
        textDecoration: `none`,
        stroke: `currentcolor`,
        justifyContent: `center`,
        flexShrink: 0,
        m: 0,
        py: 0,
        px: 3,
        borderWidth: 1,
        borderStyle: `solid`,
        borderColor: `primary`,
        transition: `border-color 200ms, color 200ms, background-color 200ms,`,
        borderRadius: 3,

        [`:focus`]: {
          outline: `none`,
          boxShadow: `0 0 0 1px white, 0 0 0 3px ${theme.colors.primary}`,
        },
      }}
      {...props}
    />
  )
}

export default Button
