/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

export interface VideoProps {
  caption: string
  containerStyles?: {}
  figureStyles?: {}
  poster?: string
  src: string
}

const Video: FC<VideoProps> = ({ containerStyles, figureStyles, poster, src, caption: children }) => {
  return (
    <section id="video" sx={{ bg: `white_0b1218`, pb: 4, pt: 5, ...containerStyles }}>
      <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4 }}>
        <figure sx={{ pt: 3, m: 0, ...figureStyles }}>
          <video
            sx={{
              aspectRatio: `16/9`,
              borderRadius: `6px`,
              boxShadow: `0 2px 8px -2px rgba(0, 0, 0, 0.25)`,
              width: `100%`,
            }}
            {...{ poster, src }}
            controls
          />
          <figcaption sx={{ mt: 3, px: 3, textAlign: `center` }} {...{ children }} />
        </figure>
      </div>
    </section>
  )
}

export default Video
